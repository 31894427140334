<template lang="">
  <div style="width: 100%;">
   <template v-for="(img,index) in imgs">
     <img  :key="index" :src="img.imgUrl" :class="img.className" v-if="img.className" />
     <img  :key="index" :src="img.imgUrl" width="100%" v-else />
   </template>
		</div>	
</template>
<script>
export default {
   data() {
    return {
      imgs:[
        {
          className:'title',
          imgUrl:require('@/assets/img/biz/1210/1.png')
        },{
          className:'imgStyle',
          imgUrl:require('@/assets/img/biz/1210/HG-1-1-2.png')
        },{
          className:'title',
          imgUrl:require('@/assets/img/biz/1210/2.png')
        },{
          className:'imgStyle',
          imgUrl:require('@/assets/img/biz/1210/HG-1-1-1.png')
        },{
          className:'',
          imgUrl:require('@/assets/img/biz/1210/HG-1-1.png')
        },{
          className:'',
          imgUrl:require('@/assets/img/biz/1210/HG-1-2.png')
        },{
          className:'',
          imgUrl:require('@/assets/img/biz/1210/HG-1-3.png')
        },{
          className:'',
          imgUrl:require('@/assets/img/biz/1210/HG-1-4.png')
        },
      ]
    }
   },
}
</script>
<style lang="less" scoped>
   .title {
   
    width:50%;
    margin:0 auto;
   }
   .imgStyle {
      padding: 0 20px; 
      box-sizing: border-box;
      width:100%;
   }
</style>